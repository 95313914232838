import { template as template_4c76e33bb51e4f34bba4c7f21ec79986 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_4c76e33bb51e4f34bba4c7f21ec79986(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
