import { template as template_838c60d27a5d471796bcba6e4dc830e7 } from "@ember/template-compiler";
const FKText = template_838c60d27a5d471796bcba6e4dc830e7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
